<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>


      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click="exportXlsxClicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_XLSX')}}</a
      >

    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">

      <div class="d-md-flex justify-content-between align-items-center mb-4">


        <b-form-group
          id="status-filter-group"
          label="Status"
          label-for="status-filter"
        >
          <b-form-select
            id="status-filter"
            class="mr-sm-2 mx-0"
            v-model="filters.redeemStatusOption"
            :options="redeemStatusOptions"
          ></b-form-select>
        </b-form-group>


        <div class="d-sm-flex justify-content-end align-items-center w-100">
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="redeem_code-table"
          ></b-pagination>
        </div>
      </div>



      <b-table
          id="redeem_code-table"
          :fields="headers"
          :filter="filters"
          :filter-function="customFilter"
          :items="ajaxPagination"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          head-variant="light"
          ref="redeemCodesTable"
          selectable
          selected-variant=""
          no-select-on-click
          @row-selected="onRowSelected"
          @filtered="onFiltered"
          @sort-changed="onFiltered"
        >
        <template #cell(status)="row">
          <v-chip
            style="font-size: 11px;"
            v-if="row.item.status === 'EXPIRED'"
            color="red"
            outlined
          >
            Utgånget
          </v-chip>

          <v-chip
            style="font-size: 11px;"
            v-if="row.item.status === 'DELETED'"
            color="red"
            outlined
          >
            Raderat
          </v-chip>

          <v-chip
            style="font-size: 11px;"
            v-if="row.item.status === 'REDEEMED'"
            color="green"
            outlined
          >
            Använt
          </v-chip>

          <v-chip
            style="font-size: 11px;"
            v-if="row.item.status === 'ACTIVE'"
            color="orange"
            outlined
          >
            Aktiv
          </v-chip>
        </template>
        <template #cell(actions)="row">
          <div class="d-flex justify-content-end">
            <a v-if="row.item.member" class="btn btn-icon btn-light btn-sm mx-2" @click="gotoMember(row.item)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary"></i>
              </span>
            </a>

          </div>
        </template>
      </b-table>

    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'redeem-codes-table',
  props: [],
  emits: ['show_history_toggled'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  watch: {
    currentPeriodId(newValue, oldValue) {
      if (newValue !== oldValue && oldValue) {
        this.initGet();
        this.$refs['orderTable'].refresh();
      }
    },
    show_history(newcheck, oldcheck) {
      this.$emit('show_history_toggled', newcheck);
    }
  },
  mounted() {
    this.initGet();
  },
  methods: {
    exportXlsxClicked() {
      axios
        .post(`/redeem_code/xlsx/${this.currentCompanyId}/${this.currentPeriodId}`, {
          period_id: this.currentPeriodId
        })
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte exportera');
        });
    },
    onFiltered(userFilteredOrders) {

    },
    ajaxPagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };


      let url = `/redeem_code/pagination/${this.currentCompanyId}/${this.currentPeriodId}/?page=${this.currentPage}`;

      const status =
        (this.filters.redeemStatusOption === 'ALL' || !this.filters.redeemStatusOption) ? '' : `&status=${this.filters.redeemStatusOption}`;

      url = `${url}${status}`;

      axios
        .get(url)
        .then(res => {
          var arr = res.data;
          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta sida');
          console.error('pagination get error', err);
        });


      return null;
    },

    initGet() {
      axios
        .get(`/redeem_code/count/${this.currentCompanyId}/${this.currentPeriodId}`)
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta antal betalningar');
        });
    },

    onRowSelected(row) {},
    gotoMember(item) {
      this.$router.push(`/ml-member-editor/${item.redeemed_by_member.member_id}`);
    },
    copyDinteroId(item) {
      navigator.clipboard
        .writeText(
          item.dintero_id
        )
        .then(() => {
          this.toastr('success', 'Kopierad', 'Dintero ID kopierat till urklipp');
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte kopiera Dintero ID');
        });
    },
    customFilter(row, filters) {

      const status = filters.redeemStatusOptions ? filters.redeemStatusOptions.code : null;

      if (status && this.getStatus(row.status) !== status) {
        return false;
      }

      return true;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      //return 'table-success';
    },

  },
  data() {
    return {
      redeemStatusOptions: [
        { value: 'ALL', text: this.$t('COMMON.ALL') },
        { value: 'ACTIVE', text: 'Aktiv' },
        { value: 'DELETED', text: 'Raderat' },
        { value: 'REDEEMED', text: 'Använt' },
        { value: 'EXPIRED', text: 'Utgånget' },
      ],
      perPage: 100,
      currentPage: 1,
      totalRows: 0,
      filters: {
        redeemStatusOption: 'ALL',
      },
      headers: [
        {
          key: 'redeemed_by_member.member_id',
          label: 'Medlem/Person',
          sortable: false,
          formatter: (_, __, item) => {
            if (item.redeemed_by_member) {
              return '(M) ' + item.redeemed_by_member.firstname + ' ' + item.redeemed_by_member.lastname;
            }
            return '-';
          }
        },
        {
          key: 'created_at',
          label: 'Skapad',
          sortable: false,
        },
        {
          key: 'is_ticket',
          label: 'Typ',
          sortable: false,
          formatter: (_, __, item) => {
            if (item.is_ticket) {
              return 'Biljett';
            }
            return 'Medlemskap';
          }
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    };
  }
};
</script>
